<template>
  <section class="related updates">
    <div>
      <h2>
        Обновления каталога
        <nuxt-link :to="'/updates/foreign-serial'">
          Сериалы
        </nuxt-link>
      </h2>
      <ul v-if="docs.serials">
        <li v-for="(serial, i) in docs.serials" :key="`index-update-serial-${i}`" v-once>
          <div class="body">
            <div class="poster">
              <img
                :data-src="filmPoster(serial)"
                :alt="serial.title_ru"
                src="/no-poster.png"
                class="lazyload"
              >
              <sup>{{ serial.updated_at | dataFilter }}</sup>
            </div>
            <div>
              <strong v-if="serial.title_ru.length > 30">{{ serial.title_ru.slice(0, 30) }}...</strong>
              <strong v-else>{{ serial.title_ru }}</strong>
              <span v-if="serial.se">
                сезон {{ serial.se.s }} эпизод {{ serial.se.e }}
                ({{ serial.translator.name }})
              </span>
            </div>
          </div>
          <nuxt-link
            :to="`/watch/${
              postUrl(serial.kinopoisk_id || serial.mdl_id || serial.wa_id, serial.title_ru)
            }?q=t${serial.translator.id}_s${serial.se.s}_e${serial.se.e}`"
          />
        </li>
      </ul>
      <p v-else>Нет обновлений</p>
    </div>
    <div>
      <h2>
        Обновления каталога
        <nuxt-link :to="'/updates/foreign-movie'">
          Фильмы
        </nuxt-link>
      </h2>
      <ul v-if="docs.movies">
        <li v-for="(movie, i) in docs.movies" :key="`index-update-movie-${i}`" v-once>
          <div class="body">
            <div class="poster">
              <img
                :data-src="filmPoster(movie)"
                :alt="movie.title_ru"
                src="/no-poster.png"
                class="lazyload"
              >
              <sup>{{ movie.updated_at | dataFilter }}</sup>
            </div>
            <div>
              <strong v-if="movie.title_ru.length > 30">{{ movie.title_ru.slice(0, 30) }}...</strong>
              <strong v-else>{{ movie.title_ru }}</strong>
              <span v-if="movie.source_type">
                {{ movie.source_type }}
                ({{ movie.translator.name }})
              </span>
            </div>
          </div>
          <nuxt-link
            :to="`/watch/${postUrl(movie.mdl_id || movie.wa_id || movie.kinopoisk_id, movie.title_ru)}`"
          />
        </li>
      </ul>
      <p v-else>Нет обновлений</p>
    </div>
  </section>
</template>

<script>
export default {
  props: { docs: { type: Object, required: true, default: () => ({})  } },
  filters: {
    dataFilter(val) {
      const months = [
        'янв',
        'фев',
        'мар',
        'апр',
        'мая',
        'июн',
        'июл',
        'авг',
        'сен',
        'окт',
        'ноя',
        'дек'
      ]
      const day = new Date(val).getDate()
      const month = new Date(val).getMonth()
      return day + ' ' + months[month]
    }
  }
}
</script>
